<template>
  <div>
    <h3>Chat Detail - {{ selectedConvo.name }}</h3>
    <div class="conversation-messages">
      <ul class="conversation">
        <li
          v-for="m in selectedConvo.messages"
          :key="m.id"
          :class="messageStyle(m)"
        >
          <div>
            <div>
              <span>{{ formatDate(m) }}</span>
            </div>
            <div>{{ m.body }}</div>
          </div>
        </li>
      </ul>
    </div>
    <div
      style="clear: both"
      class="flex flex-row justify-content-center mb-4 ml-4"
    >
      <InputText style="width: 75%" v-model="this.pendingMessage" @focus="onInputFocus" @blur="onInputBlur"/>
      <Button
        @click="sendMessage"
        class="ml-2 p-button-primary"
        :loading="loading"
        >Send Message</Button
      >
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default {
  components: {
    Button,
    InputText,
  },
  name: 'Conversation',
  props: {
    onInputFocus: {
      type: Function,
      required: true
    },
    onInputBlur: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      pendingMessage: '',
      loading: false,
    };
  },
  computed: {
    ...mapState({
      selectedConvo: (state) => state.selectedConvo,
    }),
  },
  methods: {
    sendMessage() {
      this.loading = true;

      const message = {
        message: this.pendingMessage,
        recipient: this.selectedConvo.party1,
      };

      axios
        .post('/api/messages', message)
        .then((resp) => {
          this.$store.dispatch('addMessage', resp.data);
          this.pendingMessage = '';
          this.loading = false;
        })
        .catch((err) => {
          console.log(
            'Message not successfully sent. Response:' + JSON.stringify(err)
          );
        });
    },
    formatDate(msg) {
      if (msg.dateSent === null) {
        return new Date(msg.dateCreated).toLocaleTimeString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      }

      return new Date(msg.dateSent).toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    },
    messageStyle(msg) {
      let className = '';
      if (this.selectedConvo.party2 === msg.from) {
        className = 'lunchbot-message';
      } else {
        className = 'family-message';
      }

      return className;
    },
  },
};
</script>
