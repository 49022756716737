<template>
  <p v-if="paused">Refresh Paused While Input Focused</p>
  <p v-else>Refreshing in {{ countdown }}</p>
  <div>
    <div class="dashboard">
      <div class="sidebar">
        <ConversationList/>
      </div>
      <div class="content-container">
        <Conversation :onInputFocus="haltTimer" :onInputBlur="startTimer"/>
      </div>
    </div>
  </div>
</template>
<script>
import ConversationList from '../components/ConversationList.vue';
import Conversation from '../components/Conversation.vue';

const COUNTDOWN_START = 30;
export default {
  data() {
    return {
      error: '',
      countdown: COUNTDOWN_START,
      paused: false,
      intervalId: null
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    haltTimer() {
      this.paused = true;
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.countdown = COUNTDOWN_START;
    },
    startTimer() {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(this.intervalId);
            location.reload();
          }
        }, 1000);
      }
      this.paused = false;
    }
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  components: {
    ConversationList,
    Conversation,
  }
};
</script>
